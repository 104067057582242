import React, {useState, useEffect} from 'react'
import ym from "react-yandex-metrika";
import styled from 'styled-components'
import Logo from './imagesForPromo/logo.svg'
// import Site from './imagesForPromo/Suite.png'
import Cupp from './imagesForPromo/Cupp.svg'
import Cupp2 from './imagesForPromo/Cupp2.svg'
import AppStore from './imagesForPromo/AppStore.png'
import PlayMarket from './imagesForPromo/PlayMarket.png'
import Gift from './imagesForPromo/Gift.png'
import { ReactComponent as IconSocialWhatsup } from './imagesForPromo/IconSocialWhatsup.svg';
import { ReactComponent as IconSocialTelegram } from './imagesForPromo/IconSocialTelegram.svg';
import { ReactComponent as IconSocialViber } from './imagesForPromo/IconSocialViber.svg';
import { ReactComponent as IconSocialVk } from './imagesForPromo/IconSocialVk.svg';
import IconBecycle from './imagesForPromo/IconBecycle.png'
import IconShop from './imagesForPromo/IconShop.png'
import IconWallet from './imagesForPromo/IconWallet.png'
import IconList from './imagesForPromo/IconList.png'
import useDeviceOS from '../hooks/useDeviceOS';
// import Montserrat from '../../public/fonts/Montserrat-Italic-VariableFont_wght.ttf';

const Landing = (props) => {
  const os = useDeviceOS();
  console.log(os)

  // if (os === 'Android') {
  //   window.location.href = 'https://play.google.com/store/apps/details?id=air.com.menu4me.dinner.sitiyoffice';
  //   return null; // Возвращаем null, чтобы не рендерить ничего
  // } else if (os === 'iOS') {
  //   window.location.href = 'https://apps.apple.com/us/app/%D1%81%D1%8B%D1%82%D1%8B%D0%B9/id1361986950';
  //   return null;
  // }

const WINDOWKITCHENS =
!window.location.href.includes('51obed.ru')
&& !window.location.href.includes('51.menu4me-test.ru')
&& !window.location.href.includes('stolovka.su')
&& !window.location.href.includes('straus.menu4me-test.ru')
// && !window.location.href.includes('so.menu4me-test.ru')
  const MetricAppStore=()=>{
    if (WINDOWKITCHENS){
      ym('reachGoal','click_to_app_store');

      window._tmr.push({
        type: 'reachGoal',
        id: 3473633,
        goal: 'Click_iOs_Button'
      });
    }
  }
  const MetricPlayMarket=()=>{
    if (WINDOWKITCHENS){
      ym('reachGoal','click_to_google_play');

      window._tmr.push({
        type: 'reachGoal',
        id: 3473633,
        goal: 'Click_Android_button'
      });
    }
  }
  const MetricAPK=()=>{
    if (WINDOWKITCHENS){
      ym('reachGoal','click_to_download_apk');

      window._tmr.push({
        type: 'reachGoal',
        id: 3473633,
        goal: 'Click_APK_button'
      });
    }
  }
  const MetricSite=()=>{
    if (WINDOWKITCHENS){
      ym('reachGoal','click_to_site');  

      window._tmr.push({
        type: 'reachGoal',
        id: 3473633,
        goal: 'Click_Site_button'
      });
    }
  }

  return (
    <Wrapper style={props.isPaid ? {minHeight: '100vh'} : null}>
      <Helpers>
        <img  src={Logo}/>
        {props.isPaid ? (
          <>
            <OrderTitle>Ваш заказ оплачен</OrderTitle>
            <OrderMessage>Вернитесь в приложение, чтобы проверить статус заказа</OrderMessage>
          </>
        ) : (
          <div className='Tittle' style={{textAlign: 'center'}}>Бесплатная доставка<br/>обедов на работу<br/>и домой!</div>
        )}

        {props.cuppon &&
        (
          props.cuppon === 'Promo' ?
          (
          <img style={{paddingBottom:24}} src={Cupp} />
          )
          :
          (
          <img style={{paddingBottom:24}} src={Cupp2} />
          )
        )
        }
      </Helpers>

      {!props.isPaid ? (
        <>
          <Btn>
            <div style={{marginBottom: '20px', fontSize: '18px', color: '#464646', textAlign: 'center'}}>Заказы принимаем в нашем<br/>удобном приложении</div>
            <div style={{display: 'flex', gap: '8px', width: '100%'}}>
              <a target='_blank' href='https://itunes.apple.com/us/app/%D1%81%D1%8B%D1%82%D1%8B%D0%B9-%D0%BE%D1%84%D0%B8%D1%81-%D0%B4%D0%BE%D1%81%D1%82%D0%B0%D0%B2%D0%BA%D0%B0-%D0%BE%D0%B1%D0%B5%D0%B4%D0%BE%D0%B2/id1361986950?l=ru&ls=1&mt=8'style={{flex: '180', padding: 0}} >
                <button style={{padding: 0,width:'100%',border:'none',background:'#ffffff'}} onClick={MetricAppStore}>
                  <div className='AppStore' style={{marginBottom: 8, padding: '15px'}}><ImgCompany  src={AppStore}/>App Store</div>
                </button>
              </a>
              <a  target='_blank' href='https://play.google.com/store/apps/details?id=air.com.menu4me.dinner.sitiyoffice'style={{flex:'195', padding: 0}} >
                <button style={{padding: 0,width:'100%',border:'none',background:'#ffffff'}} onClick={MetricPlayMarket}>
                  <div className='AppStore' style={{marginBottom: 8, padding: '15px'}}><ImgCompany  src={PlayMarket}/>Google Play</div>
                </button>
              </a>
            </div>
            <a target='_blank' href='https://api.menuforme.online/files/temp/sitiy_release.apk' style={{width: '100%', padding: '10px'}}>
              <button style={{padding: 0, width: '100%', border: 'none', background: '#ffffff'}} onClick={MetricAPK}>
                <div style={{fontSize: '18px', color: '#406FCD', textDecoration: 'underline'}}>
                  Скачать APK-файл
                </div>
              </button>
            </a>
            {/* <a  target='_blank'  href='/'style={{width:'100%',padding: 0,marginTop:24}} >
              <button style={{padding: 0,width:'100%',border:'none',background:'#ffffff'}} onClick={MetricSite}>
              <SuiteHref >
                <ImgCompany style={{width:28,height:28}} src={Site}/> <div className='tittle'>Посмотреть на сайте</div>
            </SuiteHref>
              </button>
            </a> */}
          </Btn>

          <div style={{margin: '25px 0', display: 'flex', justifyContent: 'center', gap: '16px', width: '100%'}}>
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: 'fit-content'}}>
              <img style={{maxWidth: '90px'}} src={Gift}/>
            </div>
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: 'fit-content'}}>
              <p style={{fontSize: '20px', lineHeight: '25px', color: '#5D80D9', textAlign: 'center'}}>250 баллов/рублей<br/>за регистрацию<br/>в приложении</p>
            </div>
          </div>

          <Social>
            <SocialWrapper>
              <SocialTitle>Чат поддержки</SocialTitle>
              <SocialGroup>
                <a href={'https://wa.me/79914590879'} target="_blank" rel="noopener nofollow"><IconSocialWhatsup/></a>
                <a href={'https://telegram.me/sitiy_support_bot'} target="_blank" rel="noopener nofollow"><IconSocialTelegram/></a>
                <a href={'viber://pa?chatURI=sitiy'} target="_blank" rel="noopener nofollow"><IconSocialViber/></a>
                <a href={'https://vk.com/sytofis'} target="_blank" rel="noopener nofollow"><IconSocialVk/></a>
              </SocialGroup>
            </SocialWrapper>
            <SocialSign>Ботов нет. У нас только люди 😊</SocialSign>
          </Social>

          <Cards>
            {[
              {
                title: 'Бесплатная доставка обедов',
                text: 'Даже при заказе на 100 ₽',
                icon: IconBecycle,
                color: '#A3D6E199',
              },
              {
                title: 'Заказывайте в любимых заведениях',
                text: 'Доставляем из местных столовых и кафе на любой вкус и кошелёк',
                icon: IconShop,
                color: '#F0DB9199',
              },
              {
                title: 'Минимальная сумма заказа 100 ₽',
                text: 'Можно собрать один заказ сразу в нескольких кухнях\nОплата картой онлайн',
                icon: IconWallet,
                color: '#90CCAF99',
              },
              {
                title: 'Заключаем договоры с предприятиями',
                text: 'Решаем вопросы с питанием ваших сотрудников\nДоставляем обеды, ужины, помогаем организовать банкет в офисе',
                icon: IconList,
                color: '#CCCBED',
              },
            ].map(item => (
              <CardWrapper style={{background: item.color}}>
                <CardTitle>{item.title}</CardTitle>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                  <CardText>{item.text}</CardText>
                  <CardIcon src={item.icon}/>
                </div>
              </CardWrapper>
            ))}
          </Cards>
        </>
      ) : null}

      <Foot style={{
        ...(props.isPaid ? { position: 'fixed', bottom: 0 } : {}),
      }}>
        <p>ООО "Бизнес-Софт"</p>
        <p>ИНН: 6321264045</p>
        <p className='foot' >
          <a className='tel' href="tel:+7-800-200-69-24">+7-800-200-69-24</a>
        </p>
      </Foot>
    </Wrapper>
  )
}

export default Landing

const Wrapper = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
margin: 0 auto;
padding: 0 10px;
max-width: 480px;
`
const Helpers =styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
margin-bottom: 25px;
 .Tittle{
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 36px;
  letter-spacing: 0.0779122px;
   padding-top:15px;
   padding-bottom: 24px;
   /* padding-left: 23px; */
   @media (min-width:300px) {
    width: 271px;
   }
   @media (max-width:300px) {
    width: 220px;
   }
 }
`
const Btn = styled.div`
display: flex;
flex-direction: column;
align-items: center;
width:100%;
max-width: 480px;
 .AppStore{
    display: flex;
    width:100%;
    align-items: center;
    justify-content: center;
    background-color:#000000;
    border-radius: 16px;
    font-weight: 400;
    /* font-size: 18px; */
    line-height: 116.9%;
    letter-spacing: 0.160192px;
    color: #FFFFFF;
    font-style: normal;
    font-family: 'Montserrat';
    @media (min-width:300px) {
      padding:18px 32px 18px 32px;
   }
   @media (max-width:335px) {
      padding:9px 16px 9px 16px;
   }
 }
`
const Foot = styled.div`
width: 100vw;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
background-color:#2E2E2E;
font-family: 'Montserrat';
font-style: normal;
color:#ffffff;
letter-spacing: 0.160192px;
font-weight: 400;
font-size: 14px;
padding: 15px 20px;
p{
  margin-bottom: 0;
}
.foot{
}
.tel{
color:#ffffff;
}
`
const SuiteHref= styled.div`
display: flex;
align-items: center;
justify-content: center;
  .tittle{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 116.9%;
    letter-spacing: 0.160192px;
    text-decoration-line: underline;
    color: #406FCD;
  }
`
const ImgCompany = styled.img`
margin-right:8px;
@media (min-width:300px) {
  width:24px;
  height:24px;
   }
   @media (max-width:300px) {
    width:16px;
    height:16px;
   }
`
const OrderTitle = styled.p`
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 36px;
  letter-spacing: 0.0779122px;
  padding-top: 15px;
  padding-bottom: 24px;
  width: 100%;
  text-align: center;
  margin-bottom: 0;
`
const OrderMessage = styled.p`
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.0779122px;
  padding-top: 15px;
  padding-bottom: 24px;
  width: 80%;
  text-align: center;
`
const Social = styled.div`
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
`
const SocialWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 15px 10px;
  background: #C9E5FF;
  border-radius: 16px;
`
const SocialTitle = styled.div`
  margin-bottom: 10px;
  font-size: 18px;
  color: #464646;
`
const SocialGroup = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 25px;
  svg{
    height: 100%;
    width: 42px;
  }
`
const SocialSign = styled.a`
  font-size: 13px;
  color: #464646;
`
const Cards = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  margin-bottom: 50px;
`
const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 32px;
  border-radius: 20px;
`
const CardTitle = styled.div`
  margin-bottom: 12px;
  width: 90%;
  font-size: 22px;
  line-height: 25px;
  font-weight: 700;
  color: #111111;
`
const CardText = styled.div`
  font-size: 14px;
  line-height: 17px;
  color: #111111;
  white-space: pre-wrap;
`
const CardIcon = styled.img`
  margin-top: auto;
  width: 48px;
  height: 48px;
`